import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/NewsletterTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.599999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA3XAAAN1wFCKJt4AAABCElEQVQY042RQUsCcRDF/VB9g+59gk6eOhTRpaBbh5CgYCuiTkYGah6CSIwiyiRbiiyVFg+5EoS6LO1/XS0oXffXriC1atCDOcyDeW/mTQAXjuMwhFHcH/g9H/CaPqG/W5T0V1ShUTbqjDIb7L/abR/vE8y8KEjyMfv5NHuFS87kO0LhCBvxBOuxBPHTc0K7EVJZGV2YhI+SZAvF3qzd7f4I9h287WpNgfho8WzWWdjaZnFzh+DSMtMra0zMzTO7KjE2GewJj0/NcOKKe+jY9vCGWsvkvlom55ZqamhvBqnrG26fFDIPeYTVRIoecHiR5ir3iKJWMBqW/+TBbDynz46by/9/4sv1G2shujMHTg19AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png",
          "srcSet": ["/static/c9103bc33c8add5ab1fa4fa1c49c90ef/43fa5/logo-wordmark.png 250w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/c6e3d/logo-wordmark.png 500w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "work-at-coqui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#work-at-coqui",
        "aria-label": "work at coqui permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👩‍💻Work at Coqui`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`We’re still hiring!`}</p>
    <p>{`An open source remote-friendly Berlin based startup founded by the creators of Mozilla’s
text-to-speech (TTS) and speech-to-text (STT) engines (over 800K downloads and 30K GitHub
stars), with the backing of top-flight investors `}<em parentName="p">{`and`}</em>{` we’re hiring!`}</p>
    <p>{`What’s not to love?`}</p>
    <p>{`We’re hiring across-the-board for a number of roles; so, there’s something for everyone:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/head-of-product"
        }}>{`Head of Product`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-full-stack-engineer"
        }}>{`Senior Full Stack Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-stt-deep-learning-engineer"
        }}>{`Senior STT Deep Learning Engineer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-developer-community-manager"
        }}>{`Senior, Developer Community Managers`}</a></li>
    </ul>
    <p>{`The full list of open positions is available on our `}<a parentName="p" {...{
        "href": "/jobs"
      }}>{`jobs page`}</a>{`.`}</p>
    <p>{`We’d love to hear from you; so, if any roles pique your interest, reach out to
`}<a parentName="p" {...{
        "href": "mailto:jobs@coqui.ai"
      }}>{`jobs@coqui.ai`}</a>{`. 🐸!`}</p>
    <h3 {...{
      "id": "hello-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hello-",
        "aria-label": "hello  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Hello! 👋🐸`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`I don’t know how we pack it all into a month. But, somehow, we do! Coqui’s
got lots going on! 🔥🔥🔥`}</p>
    <p>{`We released a TTS demo, YourTTS, that allows you to clone your voice with
only 20-30 seconds of voice data. If that weren’t enough, you don’t `}<em parentName="p">{`only`}</em>{`
get a clone that works in the language you spoke, but the clone works in
any number of other languages too! The demo works for English, Brazilian
Portuguese, and French. Check it out `}<a parentName="p" {...{
        "href": "/demo"
      }}>{`here`}</a>{`. This newsletter gives
a few details on how the demo went from zero to (number) one on Hacker
News. 🚀🚀🚀`}</p>
    <p>{`We’ve also collaborated with our old friends at Common Voice, releasing a
Python notebook that allows individuals to download their own voice data
and fine-tune STT models `}<em parentName="p">{`for their own voice!`}</em>{` Holy customization Batman!`}</p>
    <p>{`As if that weren’t enough, Leon, the `}<a parentName="p" {...{
        "href": "https://github.com/topics/virtual-assistant?o=desc&s=stars"
      }}>{`most popular`}</a>{`
virtual assistant on GitHub, just added Coqui as its default speech-to-text
provider. 👏🏿👏🏿👏🏿`}</p>
    <p>{`Not to rest on our laurels, we just pushed STT 1.2.0 out the door! It’s
packed with lots of new features: Python 3.10 support, Ogg/Vorbis support,
`}<a parentName="p" {...{
        "href": "https://github.com/webdataset/webdataset"
      }}>{`WebDataset`}</a>{` support, and much more!`}</p>
    <p>{`Also, MozFest. March 7-10, 2022, save the date! We’ll be hosting one roundtable
and one hackathon. So, if you’ve been wanting to hear what we’re up to or simply
itching to get your hands dirty with our tech, signup! (Details below the fold!)`}</p>
    <p>{`One last thing, `}<a parentName="p" {...{
        "href": "https://github.com/petewarden/spchcat"
      }}>{`spchcat`}</a>{`, `}<inlineCode parentName="p">{`cat`}</inlineCode>{` for
speech on Linux, built with Coqui STT 🐸 inside. Read on to find the deatils.`}</p>
    <p>{`Enjoy the newsletter! 🐸`}</p>
    <h3 {...{
      "id": "zero-to-number-one-on-hacker-news",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#zero-to-number-one-on-hacker-news",
        "aria-label": "zero to number one on hacker news permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Zero to (Number) One (On Hacker News)🔥🔥🔥`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "24.400000000000002%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAFABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAQFB//EABcBAAMBAAAAAAAAAAAAAAAAAAECAwT/2gAMAwEAAhADEAAAAd2sDPWSFP8A/8QAHBAAAQMFAAAAAAAAAAAAAAAAAwABAgQFERIU/9oACAEBAAEFAnpA7wtwccYF/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAITUVL/2gAIAQMBAT8Bo+lX6P/EABgRAAMBAQAAAAAAAAAAAAAAAAACURIT/9oACAECAQE/AcLDmkP/xAAbEAACAgMBAAAAAAAAAAAAAAAAAQOSEyMzk//aAAgBAQAGPwJ6o6ITxx+aOEdEf//EABkQAAIDAQAAAAAAAAAAAAAAAAABESEx8P/aAAgBAQABPyGJRXxRtG0CXrQ//9oADAMBAAIAAwAAABBz7//EABkRAAIDAQAAAAAAAAAAAAAAAAABEUGRYf/aAAgBAwEBPxCC+s6tZ//EABgRAAMBAQAAAAAAAAAAAAAAAAABkVFh/9oACAECAQE/EHniOGI//8QAGRABAAMBAQAAAAAAAAAAAAAAAQARUcEx/9oACAEBAAE/EKrMvzdgxdAvINSW1cn/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/2969fd52b11ffea9df2efdc0d0afab4f/dbdff/HackerNews.jpg",
          "srcSet": ["/static/2969fd52b11ffea9df2efdc0d0afab4f/0988f/HackerNews.jpg 250w", "/static/2969fd52b11ffea9df2efdc0d0afab4f/d1f95/HackerNews.jpg 500w", "/static/2969fd52b11ffea9df2efdc0d0afab4f/dbdff/HackerNews.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`Boom!🧨🧨🧨`}</p>
    <p>{`January 3 saw the release of Coqui’s YourTTS demo which, in the matter of a few
short hours, went from zero to one on Hacker News! 🚀🚀🚀 (In case you didn’t
get a chance to kick the tires on the demo, it’s still `}<a parentName="p" {...{
        "href": "/demo"
      }}>{`here`}</a>{`.
However, it won’t be there forever. Get going!)`}</p>
    <p>{`When we were preparing the demo, we felt it was something special and could, just
possibly, catch on. So, we built the backend to withstand the Hacker News hug of
death. A load balancer (check), auto-scaling group (check), automatic cluster
healing (check). Things seemed ready and prepped for the deathly love hug of
Hacker News.`}</p>
    <p>{`Through a bit of benchmarking and back-of-the-envelope calculations, we thought
the hug could be survived if we put 26 machines behind the load balancer. (At
the time it seemed overkill, but math is math and doesn’t care about your
feelings. So, 26 it was.) As things progressed, 26 looked to be right on target.
Getting onto the front page, climbing the rankings, 30th, 20th, 15th, 10th, 5th,
4th, all smooth sailing! However, as we started to inch towards 3rd, 2nd, and
1st, it was clear we “needed a bigger boat”. So, we quickly (It was creeping
towards midnight in Berlin) enlisted the services of 10 more machines to keep
us from sinking. 36 was our magic number!`}</p>
    <p>{`Thanks for the love Hacker News!`}</p>
    <p>{`For the curious, the deathly love hug of Hacker News looks like this`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAxElEQVQoz61Suw7DIAzM//9elw5dKrVNH2kgJGAehisma9WhiaUTtiWOO5suxgiggHOGj9zyUv5HF0KABHMGhdTyTYRE1EgWipgpbidMaVWllgDjdiD03gsDQmKkanu3GVqfcNeuLWcHy6WpO5xH9Mq1B3LeoFAuy4ZdVXm8arg/ty3RlWrRUcD5aVAyw1iPU69xGWbc3jOe2uKhFrwmW3sGj1q/jcNQa70Q1EwYK4bJwVaebrUnc8vtL8opkN4vMPPX/gdP8BVeT5djIAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/aa320f023422bb7a799d0de8df9b852b/da8b6/HackerNewsGraph.png",
          "srcSet": ["/static/aa320f023422bb7a799d0de8df9b852b/43fa5/HackerNewsGraph.png 250w", "/static/aa320f023422bb7a799d0de8df9b852b/c6e3d/HackerNewsGraph.png 500w", "/static/aa320f023422bb7a799d0de8df9b852b/da8b6/HackerNewsGraph.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`A graph of time vs users per minute. As you might have guessed, the peak is when
we hit first place. A little over 2.8K requests per minute, not bad for our
little demo.🔥🔥🔥`}</p>
    <p>{`If you’d like to learn more about YourTTS, we got you covered. With a
`}<a parentName="p" {...{
        "href": "/blog/tts/yourtts-zero-shot-text-synthesis-low-resource-languages"
      }}>{`blog post`}</a>{`,
a `}<a parentName="p" {...{
        "href": "https://edresson.github.io/YourTTS/"
      }}>{`project page`}</a>{`, a
`}<a parentName="p" {...{
        "href": "https://colab.research.google.com/drive/1ftI0x16iqKgiQFgTjTDgRpOM1wC1U-yS?usp=sharing"
      }}>{`Colab demo`}</a>{`,
and, if you still can’t get enough, a `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2112.02418"
      }}>{`research paper`}</a>{`
with all the details.`}</p>
    <h3 {...{
      "id": "coqui-stt-and-common-voice-80",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-stt-and-common-voice-80",
        "aria-label": "coqui stt and common voice 80 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui STT and Common Voice 8.0`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.400000000000006%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAwQI/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAelLVbZNBAf/xAAaEAADAQADAAAAAAAAAAAAAAACAwQBEhMU/9oACAEBAAEFAm632NNhYrl1wJFjKYZ7BSkJ1//EABYRAAMAAAAAAAAAAAAAAAAAAAEQIf/aAAgBAwEBPwE1f//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EACAQAAEEAQQDAAAAAAAAAAAAAAIAAQMREgQhMUETI2H/2gAIAQEABj8Cjq/H2y2yjovm6HJ7LtarOy9nZOhaeEJWHjNrpNHGLADcCy//xAAcEAACAgMBAQAAAAAAAAAAAAABEQAhMVFhQXH/2gAIAQEAAT8hX+UARzb5ALNQXYdjkEBKAi2Z686cXy6jQiLEx+/KAn//2gAMAwEAAgADAAAAEITf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREA/9oACAEDAQE/EBEwQm//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhMUFRYXGB/9oACAEBAAE/EAskBQKw+COu6TJHXdkIwFSlHzrgyXjUgIcrND8x10xBpqwMD0QzVnoAojLxQMG+KFDVWH1X9z//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1ee9c6ce1fb2befb2c86df06890d2937/dbdff/CV%2BCoqui.jpg",
          "srcSet": ["/static/1ee9c6ce1fb2befb2c86df06890d2937/0988f/CV%2BCoqui.jpg 250w", "/static/1ee9c6ce1fb2befb2c86df06890d2937/d1f95/CV%2BCoqui.jpg 500w", "/static/1ee9c6ce1fb2befb2c86df06890d2937/dbdff/CV%2BCoqui.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`You might know that the Coqui origin story begins at Mozilla, where we kicked off
our mission to bring open speech tools to all developers and all languages. But
did you know that we still collaborate with our old friends at Common Voice?
For the latest release of Common Voice 8.0, we cooked up something particularly
fun!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "110.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAABYlAAAWJQFJUiTwAAADWklEQVQ4y6VVyW7jRhDlt/oDDN8zN/+CD4YvE+Tmg+EMjAliILByGMPCLLKlyJY0IiVS3ClSlERxX16qWyTtLA4MpIBSdXUXq18tXRKurq5wfHwMSZph+DhC34zAqCxLVFX1Zi6KAnmeQ3j//kccHBzAXnow7CX8MMP/IWG98uC7S2z8FTa0Xi0tuI4Nz6O155Juk27B1DVYlgVdN7BcLjm7rgvDMLitoiwQxzGEke7j89TCg2TgfmahO9YwkG30ej18HX5Hb+7i23cND6KG2UInRw4s2+bOHXKq6Tp3LssyoiiCEOSAlwCrFHBJrklfUdTsVncbwUnqfdor3hIyqpKVgLjinCcxD/GZypYrZksFSCn5TeFyKgbqohSkC+ynrCpuoMxk3N11cXt7C22h7j+qq9jYOLsNLp7u0FUnuBbv8ZvU546aC4SmPRgxJx8+/IxfPn6ERcln1Jw1UvEd/PTQwVddxK/THi6fuojzlJ8Vf3coD4b4dn+PHrE6EfeO6vOyRjH1TOhbD7s0QZQlGDkqVlHwV4eM0jRFt/M77P4Yau8Rnz/dtk4uLi5wfX29DzlY45P8hEd7gZ4h4YsmtnZtyM2GoqkQh08Y9f+AbpltWTRNg02t0lBaFTB3PpxwQ7kt25SULxE25FCzutTgr1EYhliv10iiGHm6f1XbIHhGyJ04Du94hkQSRYjTKYbDIcbjMX8NDQLWGmdnZzg8PMQP797h6OgIp6enuLy85Db8LbPF+fk5Pzw5OcFoNOJdL0kSd9qE2qZFUdDv9zGlS29ubjAYDNpLiwbhZDJBp9Phh/9G5Yup8hqxC7nDiB700vOx3UWUixCKqsO0HF71LMs4s1ByLjOkSYKEXlOa1pL0iOWTbMqy2jucTEXO4mwOiV6LKM2hUJMrKg2EuQKZJgnTRZqZMsm5rNS8wEJVeQ0CKgyLQIiTFLJqQJRV6KYD1bCpfcxWaqZNawsLfc9abbPQTX6uUzSbYPdcZQY1ihPECeO0lSmF2Mg8L5CxsPNiz0XRrrMsR0Lt08wEIdiFfFoz6W+28PxNLdfUj2ue3/I/ivGP8ZUQojCM9mjoJpbTJE25ztCzRg4p6W/9jxECit8hhAwN+5ghYwiZvqOLXNcjlCve1C+nzmv0J+g1hEhbM6pqAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/b2a43765064e66fbdc05ae6c062ad0f6/da8b6/CV%2BCoquiOnTwitter.png",
          "srcSet": ["/static/b2a43765064e66fbdc05ae6c062ad0f6/43fa5/CV%2BCoquiOnTwitter.png 250w", "/static/b2a43765064e66fbdc05ae6c062ad0f6/c6e3d/CV%2BCoquiOnTwitter.png 500w", "/static/b2a43765064e66fbdc05ae6c062ad0f6/da8b6/CV%2BCoquiOnTwitter.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The Common Voice team released a new feature which allows individuals to download
their own voice data. We at Coqui saw this as an opportunity to enable individuals
to create fine-tuned STT models `}<em parentName="p">{`for their own voice!`}</em></p>
    <p>{`We released an `}<a parentName="p" {...{
        "href": "https://colab.research.google.com/drive/1-W0T1Scp_940kf4E63CY09MBOeR19kUO"
      }}>{`easy-to-follow Python notebook`}</a>{`
(runs on Colab) which will take you through all the steps to use Common Voice to
train a custom speech-to-text model just for you.`}</p>
    <p>{`You can read all about it on `}<a parentName="p" {...{
        "href": "https://foundation.mozilla.org/en/blog/raise-your-voice-training-a-model-on-your-very-own-voice-clips-with-common-voice-and-coqui/"
      }}>{`Mozilla’s blog`}</a>{`
or just try it out today!`}</p>
    <h3 {...{
      "id": "coqui-now-default-speech-to-text-for-leonai",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-now-default-speech-to-text-for-leonai",
        "aria-label": "coqui now default speech to text for leonai permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui now default Speech-to-Text for Leon.ai`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.400000000000006%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAYBAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAAFAAP/2gAMAwEAAhADEAAAAU9fpKwWYFl//8QAGxAAAwACAwAAAAAAAAAAAAAAAgMEAAEFERL/2gAIAQEAAQUCjnjPjJh943rTAteoVWvkxrmUl//EABgRAQEAAwAAAAAAAAAAAAAAAAECEBEx/9oACAEDAQE/AWhknXMf/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/ATV//8QAIBAAAQQBBAMAAAAAAAAAAAAAAgABAxESEyFBUSIjcf/aAAgBAQAGPwKU5a1W5vdviK43JPTYtwsAlIQ6ZemY4r38XpZym8h9kv/EAB0QAQEAAgIDAQAAAAAAAAAAAAERACExQVFhgaH/2gAIAQEAAT8h5gpsxtyO+v3LkSENMH5goitD4z2CAy+EbL0nGVe2rVz/2gAMAwEAAgADAAAAECc//8QAGhEAAgMBAQAAAAAAAAAAAAAAAREAITFxYf/aAAgBAwEBPxBfwsyNLLvmDycn/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEhMRH/2gAIAQIBAT8Q7xMFEf/EABwQAQEAAwADAQAAAAAAAAAAAAERACExQWGBkf/aAAgBAQABPxBaCFXG6+jOBqhk0MJxC0G1LKWDl1yDJK1LtPeafEmoRtPtf1wtvQTOgg7mKhBT5nCvjP/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/acdc6df6d3cbe3ab99fe7505e61f4146/dbdff/Leon%2BCoqui.jpg",
          "srcSet": ["/static/acdc6df6d3cbe3ab99fe7505e61f4146/0988f/Leon%2BCoqui.jpg 250w", "/static/acdc6df6d3cbe3ab99fe7505e61f4146/d1f95/Leon%2BCoqui.jpg 500w", "/static/acdc6df6d3cbe3ab99fe7505e61f4146/dbdff/Leon%2BCoqui.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`Coqui STT is now the default speech-to-text provider for Leon!`}</p>
    <p><a parentName="p" {...{
        "href": "https://getleon.ai/"
      }}>{`Leon`}</a>{` is a modern, private-by-design, open-source virtual
assistant. Maintained by `}<a parentName="p" {...{
        "href": "https://twitter.com/louistiti_fr"
      }}>{`Louis Grenard`}</a>{`, Leon
is the `}<a parentName="p" {...{
        "href": "https://github.com/topics/virtual-assistant?o=desc&s=stars"
      }}>{`most popular`}</a>{`
virtual assistant on GitHub!`}</p>
    <p>{`From `}<a parentName="p" {...{
        "href": "https://github.com/leon-ai/leon"
      }}>{`Leon’s Github`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`Leon is an open-source personal assistant who can live on your server.`}</li>
      <li parentName="ul">{`He does stuff when you ask him.`}</li>
      <li parentName="ul">{`You can talk to him and he can talk to you. You can also text him and he can
also text you. If you want to, Leon can communicate with you by being offline
to protect your privacy.`}</li>
    </ul>
    <p>{`The integration was made possible by Johann Barbie, taking advantage of the easy-to-use
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/stt"
      }}>{`🐸STT JavaScript package`}</a>{`.`}</p>
    <h3 {...{
      "id": "stt-v120",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#stt-v120",
        "aria-label": "stt v120 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🐸STT v1.2.0`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAGBAX/2gAMAwEAAhADEAAAAX1ucmRTVxhLqf/EABkQAAMBAQEAAAAAAAAAAAAAAAMEBQIBIv/aAAgBAQABBQLPOMGVXC1o/glXpVk5Vgm7z9Pax//EABwRAAICAgMAAAAAAAAAAAAAAAECACESMSJR8P/aAAgBAwEBPwFODqrDHdDXqhJ7n//EAB4RAQABAwUBAAAAAAAAAAAAAAECAAMREiFBYbHh/9oACAECAQE/AWI2pysuQxvz37RqT61//8QAIhAAAgEEAgEFAAAAAAAAAAAAAQIDABESIQQxIiNBUWJx/9oACAEBAAY/ArxZABdtPpVH52aCxWSaLd3Hi9/kURLxuRl9DkKl5gl3Enpi3RrBjksyBDlWAQde1f/EAB4QAQEAAgICAwAAAAAAAAAAAAERACExUWFxkaHB/9oACAEBAAE/IdgmzaraPZ9GQJACHkHZe8kR+FPwn7vESWgGoyvq4dQxwLZb85JjBy1n/9oADAMBAAIAAwAAABC4/wD/xAAaEQEAAgMBAAAAAAAAAAAAAAABETEAUWGB/9oACAEDAQE/EGhqpBZIXvG3wTDn/8QAGxEBAAICAwAAAAAAAAAAAAAAAREhAIExcfD/2gAIAQIBAT8Qt8xKbAwvkjpW1ETxtz//xAAdEAEBAAMBAAMBAAAAAAAAAAABEQAhMUFhcYGR/9oACAEBAAE/EOPe1WRKjODEa3JPcYFeVAEOhBKMQQrykpBca06APcRkiFCLNGH5b9YR6aKQA4EP8+cWkFIu2xdl3L+5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg",
          "srcSet": ["/static/1e77dc6fce06f5a577ab0e4d0674e779/0988f/release.jpg 250w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/d1f95/release.jpg 500w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/reuben"
      }}>{`Reuben Morais`}</a></p>
    <p>{`The latest version of Coqui STT is available on `}<a parentName="p" {...{
        "href": "https://pypi.org/project/stt/"
      }}>{`PyPI`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/stt"
      }}>{`npm`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT/releases/tag/v1.2.0"
      }}>{`our release page`}</a>{`.
It includes new features, usability and performance improvements for the training
and inference, and support for the `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2004.04410"
      }}>{`Att-HACK expressive speech dataset`}</a>{`,
thanks to a contribution by Danny Waser. The highlights are:`}</p>
    <ul>
      <li parentName="ul">{`Added Python 3.10 support`}</li>
      <li parentName="ul">{`Added new inference APIs that process pending data before returning transcription results`}</li>
      <li parentName="ul">{`Added an importer for using data from Common Voice’s new personal data downloader, and a
Jupyter notebook which creates a custom STT model using your data`}</li>
      <li parentName="ul">{`Improved and extend `}<inlineCode parentName="li">{`evaluate_tflite`}</inlineCode>{` script (now `}<inlineCode parentName="li">{`evaluate_export`}</inlineCode>{` module) with Opus support`}</li>
      <li parentName="ul">{`Added support for Ogg/Vorbis encoded audio files as training inputs`}</li>
      <li parentName="ul">{`Added an importer for the Att-HACK dataset`}</li>
      <li parentName="ul">{`Model dimensions are now loaded automatically from a checkpoint if present`}</li>
      <li parentName="ul">{`Checkpoint loader will now handle CuDNN checkpoints transparently, without an explicit flag`}</li>
      <li parentName="ul">{`When starting a training run, a batch size check will be performed automatically to help
diagnose memory issues early`}</li>
      <li parentName="ul">{`Added support for using `}<a parentName="li" {...{
          "href": "https://github.com/webdataset/webdataset"
        }}>{`WebDataset`}</a>{` for streaming
large training sets from cloud or local storage`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://stt.readthedocs.io/"
      }}>{`Try Coqui STT today by following our usage guide for your favorite programming language`}</a>{`.
Our 1.0 English model is compatible with the 1.2 release and has been trained on over
47,000 hours of speech data, including the Common Voice dataset that has over 2,000
hours of speech. It is available for download in the `}<a parentName="p" {...{
        "href": "https://coqui.ai/models/"
      }}>{`Coqui Model Zoo`}</a>{`,
where we also document the training parameters that were used to create it. You can
also find our latest release in your favorite package manager: we offer packages for
Python, Node.JS, Electron, Java on Android, and C/C++.`}</p>
    <h3 {...{
      "id": "mozfest-and-the-hackathon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mozfest-and-the-hackathon",
        "aria-label": "mozfest and the hackathon permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`MozFest and the Hackathon`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.400000000000006%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACuUlEQVQozxXSXVMTBxSA4b2SizrFWiEESGBIhAQiah1wzBBLpVilDJ9qRGDAqkGJqVIKTggfgQSDhA2b3Ww2m93ExACDF21/4tv14sy5e+Y9M0fQ1RPS6hc0tcpurkykUGZMlLgxNYVvYpyOYJBbMzP45xe4/iLMlaiG553BpTkD+2iZ9oEqtt4T2lyn3HCcIoiJMulkCWm/RDxl8CZj8jD+idHhX5keHaU7EGBweBi/tXsH7uJ5OIX70XsaXpRomjTxDX3G1l+jy1ejt+sE4XO4iBjREVd0Yut5lnY0xrYzeB8/w/XkKU0LC3Q/X8Q9N097cBbPk3k8c6vYlgycwSKukRKOn6s0W2jPTzWE6riCMaWgPFPZfqXyOiIzuaFw57CAW9Fo0TTa1ByOXA6nXKAhpdOwWeRy2Dr9VZnOpxXaRirYBy008AVB7jtC6U+Tuy9TCFdJRWu8jpdZUk+5ndXoyefxV6uMnZ0xoBUJZE0eZM+4mzqnL3bO77H/8D4q4x6x5kHFKnTuo7bvc+RNId2TiAUVFkMy9z8o3ExIdKYztEgy15QcHaKM4yDHtaTOrS2Tlvcm18MVnLMmtukS7skyQqV+G/n7HcyGXQ5ce/zdn2T+schQRMK/cczVAxmXmLXQHI2idXpGp/ujTmM0j/3PPD+GdK48N2icLeGdMRGydVGydRvULm4ht+6y6ouz/K3y4znTYo0+uWLVmQxVz3DpFe6d/stvlX9oSpT44a88XZEC9SGDDgvtWTQQihfWkerW0b/bIG3bYs0bJxRIMD6d4pfQAb7VQ65upfEkjmjeP6Z5z6rekbDFrNo1C3ynYV/WaQ0V6X5ZRPh6YQ3TAksXoxw2xoh27rDsTzAxkWLwj0/0rKTxbh7h2svQnDzmUlLGvqtweVPF8SGPc6WA7W2R+jcGrdYr/Q+QQ7gpNZ+uGAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/470b968b840d5964513b963698c3eb65/da8b6/MozFest2022.png",
          "srcSet": ["/static/470b968b840d5964513b963698c3eb65/43fa5/MozFest2022.png 250w", "/static/470b968b840d5964513b963698c3eb65/c6e3d/MozFest2022.png 500w", "/static/470b968b840d5964513b963698c3eb65/da8b6/MozFest2022.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`To spread the good word about Coqui, we’re teaming up with the Common Voice team at Mozilla
to organize two sessions at this year’s MozFest, introducing Coqui STT and TTS to a much
wider developer audience.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.mozillafestival.org/en/tickets"
      }}>{`Reserve your (free) tickets now!`}</a></p>
    <p>{`MozFest is short for “Mozilla Festival”. In Mozilla’s own words:`}</p>
    <blockquote>
      <p parentName="blockquote">{`MozFest is a unique hybrid: part art, tech and society convening, part maker festival,
and the premiere gathering for activists in diverse global movements fighting for a
more humane digital world.`}</p>
    </blockquote>
    <p>{`Coqui and Common Voice are hosting two sessions: one roundtable and one hackathon.`}</p>
    <p>{`Our roundtable discussion is called `}<strong parentName="p">{`With what words should I speak? Impact of Voice technology
on Language Diversity`}</strong>{` and will consist of a diverse and excellent panel of speakers who work
with marginalized languages and technology. This is an exciting and important opportunity to
hear people’s experiences speaking languages that Alexa doesn’t and to hear how these same
people are working to change the status quo of language technology.`}</p>
    <p>{`Our hackathon is called is called `}<strong parentName="p">{`Hack the Planet: building real tech for the world’s languages`}</strong>{`,
and is a sprint to make real, usable tech for languages typically unsupported by big tech. “Real tech”
means that contestants aren’t going to be worried about training the model with the best word error
rate on some test set. “Real tech” means taking models and integrating them into a larger project
that is real and usable. This is possible even for STT and TTS models without the highest performance
metrics. We’re driving the point home that even for languages without tons of data, you can still
build great voice-enabled software!`}</p>
    <p>{`Want to participate? `}<a parentName="p" {...{
        "href": "https://www.mozillafestival.org/en/tickets"
      }}>{`Register for MozFest`}</a>{` today!`}</p>
    <h3 {...{
      "id": "one-last-thing-some-love-from-linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#one-last-thing-some-love-from-linux",
        "aria-label": "one last thing some love from linux permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`One Last Thing, Some Love from Linux`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.6%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/8915cd429abec81cbddd24c539ea8f5d/da8b6/LinuxLove.png",
          "srcSet": ["/static/8915cd429abec81cbddd24c539ea8f5d/43fa5/LinuxLove.png 250w", "/static/8915cd429abec81cbddd24c539ea8f5d/c6e3d/LinuxLove.png 500w", "/static/8915cd429abec81cbddd24c539ea8f5d/da8b6/LinuxLove.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`We are constantly reminded of how lucky we are to have such an active and open community.
This last month in particular, we saw a new project from one of our community members get
lots of attention!`}</p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/petewarden"
      }}>{`Pete Warden`}</a>{` (head of the TensorFlow team at Google working
on small devices) took the Coqui STT toolkit, and wrapped it up in a project to seamlessly
run on Linux and Raspberry Pi. It’s called “spchcat”, and it’s something like the Unix
“cat” command but for speech.`}</p>
    <p>{`As Pete says in his
`}<a parentName="p" {...{
        "href": "https://petewarden.com/2022/01/06/launching-spchcat-an-open-source-speech-recognition-tool-for-linux-and-raspberry-pi/"
      }}>{`blogpost`}</a>{`
on spchcat:`}</p>
    <blockquote>
      <p parentName="blockquote">{`I’ve been following the Coqui.ai team’s work since they launched, and was very impressed
by the quality of the open source speech models and code they have produced. I didn’t
have an easy way to run them myself though, especially on live microphone input. With that
in mind, I decided my holiday project would be writing a command line tool using Coqui’s
speech to text library.`}</p>
    </blockquote>
    <p>{`Coqui STT has been installable on Linux and Raspberry Pi for a while, and we’ve had the
ability to use microphone input from your browser in the
`}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/stt-model-manager"
      }}>{`stt-model-manager`}</a>{`… so what’s new here?`}</p>
    <p>{`What’s new is this: you can now just doble-click on
`}<a parentName="p" {...{
        "href": "https://github.com/petewarden/spchcat/releases/download/v0.0.2-alpha/spchcat_0.0-2_amd64.deb"
      }}>{`Pete’s new installer`}</a>{`,
and run `}<inlineCode parentName="p">{`spchcat`}</inlineCode>{` from the terminal, and just start talking into your microphone… it’s so simple!`}</p>
    <p>{`Don’t believe us? Believe Pete:`}</p>
    <div align="center">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/Z2m8Xg4Ifnk" frameBorder="0" allow="accelerometer;
        autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <p>{`Check it out for yourself today, and get Coqui STT models right at your fingertips on your favorite Linux machines!`}</p>
    {
      /* markdownlint-enable line-length */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      